import React, { useState } from 'react';
import './HorizontalAccordion.css';
import sustainICon from './assets/SUSTAIN.svg';
import  showcaseIcon from './assets/showcase.svg';
import standarizeIcon from './assets/STANDARDIZE.svg';
import strategizeIcon from './assets/STRATEGIZE.svg';

const HorizontalAccordion = () => {
  const [hoveredIndex, setHoveredIndex] = useState(0);

  const items = [
    { 
      title: 'STRATEGIZE', 
      content: 'Developing robust business strategies that foster growth, including market expansion and innovative concept development.',
      icon: strategizeIcon // Placeholder for the puzzle piece icon
    },
    { 
      title: 'STANDARDIZE', 
      content: 'Standardize your food & beverage business to ensure consistent quality and operational excellence.',
      icon: standarizeIcon// Placeholder for a document icon
    },
    { 
      title: 'SUSTAIN', 
      content: 'Ensure your f&b thrives through industry best practices, efficient resource management and community engagement.',
      icon: sustainICon // Placeholder for the globe icon
    },
    { 
      title: 'SHOWCASE', 
      content: 'Reveal your dream to the world & witness how your strategies can raise your business setting you apart in the industry.',
      icon: showcaseIcon // Placeholder for an art palette icon
    }
  ];

  return (
    <div className="accordion-container">
      {items.map((item, index) => (
        <div
          key={index}
          className={`accordion-item ${hoveredIndex === index ? 'active' : ''}`}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(0)}
          style={{ flex: hoveredIndex === index ? 2 : 1 }} // Flex to make one section open by default
        >
          <div className="accordion-title">
            
            <span className={`accordion-text ${hoveredIndex === index ? 'down' : ''}`}>{item.title}</span>
          </div>
          <div className="accordion-content">
          <div className="accordion-icon"><img src={item.icon} alt='icon' /></div>
          {item.content}
          <hr className="content-divider" />
          <div className="title-icon-container">
  <div className="title">{item.title}</div>
  <i className="fas fa-arrow-down down-icon"></i>
</div>

          </div>
        </div>
      ))}
    </div>
  );
};

export default HorizontalAccordion;
