import React from "react";
import "./App.css";
import logo from './assets/Logo.svg';
import landing from './assets/landingImage.png';
import about from './assets/about.png';
import HorizontalAccordion from './HorizontalAccordion';
import section1 from './assets/section1.png';
import section2 from './assets/section2.png';
import section3 from './assets/section3.png';

function App() {
  return (
    <div className="App">
      <header className="header">
        <div className="logo">
          <img src={logo} alt="Prana Logo" />
        </div>
        <nav className="navigation">
          <a href="#home" className="home">Home</a>
          <a href="#contact" className="contact">Contact Us</a>
        </nav>
      </header>

      <section className="hero">
      <div className="hero-image">
          <img src={landing} alt="Hero" />
        </div>
        <div className="hero-content">
          <h2>
            Transform Your F&B Vision With <span className="highlight">Prana</span>
          </h2>
          <p>
            Ready to elevate your food and beverage venture? Partner with Prana for unparalleled consulting services that turn your dreams into thriving realities.
          </p>
          <button className="cta-button">Start Today</button>
        </div>
      </section>

      <section className="hero">
        <div className="hero-content">
          <h1>
          About Us
          </h1>
          <p>
          We turn your food & beverage dreams into realities with our unparalleled consulting services. Our dedicated hospitality professionals meticulously craft every detail of your restaurant, bar, QSR, or cafe to meet your vision. With extensive industry experience and a passion for excellence, we specialize in strategic planning, operational efficiency, and innovative solutions.
          </p>
          <button className="cta-button">Explore Now</button>
        </div>
        <div className="hero-image">
          <img src={about} alt="Hero" />
        </div>
      </section>

       <section className="hero">
        <div className="hero-content">
          <h1>
          Our Approach
          </h1>
          <p>
          Comprehensive suite of consulting solutions: integrating strategic insights with cutting-edge technology to optimize every aspect of your F&B outlet. Our customized approach ensures sustainable growth, efficiency gains, and enhanced profitability.
          </p>
        </div>
        <div className="hero-image">
        <HorizontalAccordion />
        </div>
      </section>

      <section className="hero-1">
        <div
  className="hero-content-1"

>
  <h1>Tailored Solution</h1>
  <p>
    Comprehensive suite of consulting solutions: integrating strategic insights with cutting-edge technology to optimize every aspect of your F&B outlet. Our customized approach ensures sustainable growth, efficiency gains, and enhanced profitability.
  </p>
</div>

      </section>
      <section className="hero">
      <div className="hero-image">
          <img src={section1} alt="Hero" />
        </div>
        <div className="hero-content">
          <h1>
          Menu your route from tongue to heart.
          </h1>
          <p1>
          Crafting unique, cost-effective menus that delight customers and reflect your market vision through expert analysis of trends and preferences.
          </p1>
          <button className="read-more-button">Read More</button>
        </div>
      </section>
      <section className="hero">
        <div className="hero-content">
          <h1>
          Operations without pain, just pure gain.
          </h1>
          <p1>
          Streamlining operations for maximum productivity and minimal costs with optimized workflows, staff training, and inventory management.
          </p1>
          <button className="read-more-button">Read More</button>
        </div>
        <div className="hero-image">
          <img src={section2} alt="Hero" />
        </div>
      </section>
      <section className="hero">
      <div className="hero-image">
          <img src={section3} alt="Hero" />
        </div>
        <div className="hero-content">
          <h1>
          Turn your whistles to sirens.
          </h1>
          <p1>
          Boosting visibility and customer engagement through comprehensive marketing solutions, including brand development, digital marketing, and targeted campaigns.
          </p1>
          <button className="read-more-button">Read More</button>
        </div>
      </section>

        
     <footer className="footer">
  <div className="footer-content">
    <div className="footer-left">
      <div className="footer-text">
        <h1>Let’s Cook Something Special</h1>
        <p>pranaconsultancy.com<br />+91 96332 37261<br />Ramanattukara<br />Kozhikode, Kerala.</p>
      </div>
      <div className="footer-nav">
        <a href="#home">Home</a>
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
      </div>
    </div>
    <div className="footer-right">
      <a href="#contact" className="contact-button">Contact Us</a>
      <div className="social-icons">
            <i className="fab fa-facebook-f"></i>
            <i className="fab fa-linkedin"></i>
            <i className="fab fa-youtube"></i>
            <i className="fab fa-instagram"></i>
          </div>
    </div>
  </div>
</footer>


    </div>
  );
}

export default App;
